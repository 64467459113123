<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:43:43
-->
<template>
	<div class="activityList">
		<div class="activityList-data">
			<div class="common-row">
				<div class="common-row-item">
					<span class="common-row-label">活动名称</span>
					<el-input class="common-row-ipt" placeholder="请输入活动名称" v-model="searchForm.activityName" />
				</div>
				<div class="common-row-item">
					<span class="common-row-label">活动ID</span>
					<el-input class="common-row-ipt" placeholder="请输入活动ID" v-model="searchForm.activityId" />
				</div>
				<div class="common-row-item">
					<el-button @click="search" type="primary">查询</el-button>
					<el-button @click="reset">重置</el-button>
				</div>
			</div>
			<el-table :data="tableData" border>
				<el-table-column  prop="id" label="活动ID">
				</el-table-column>
				<el-table-column prop="activityName" label="活动名称">
				</el-table-column>
				<el-table-column width="220px" prop="starTime" label="活动时间">
					<template slot-scope="scope"
						v-if="scope.row.starTime&&scope.row.endTime">
						<span>{{`${dateFormat(scope.row.starTime)} 至 ${dateFormat(scope.row.endTime)}`}}</span>
					</template>
				</el-table-column>
				<el-table-column width="140px" prop="activityStatus" label="活动状态">
					<template v-if="scope.row.activityStatus*1 >= 0" slot-scope="scope">
						<div v-if="scope.row.activityStatus == 2" class="table-action">
							<span
								:class="{'table-action-status_orange': scope.row.workingStatus == 1,'table-action-status_red': scope.row.workingStatus == 2,'table-action-status_black': scope.row.workingStatus == 3}">{{scope.row.workingStatus == 1 ?"工时表待提交":scope.row.workingStatus == 2 ?"工时表超时未提交":"工时表已提交"}}</span>
						</div>
						<div v-else class="table-action">
							<span
								:class="{'table-action-status_black': scope.row.activityStatus != 0,'table-action-status_red': scope.row.activityStatus == 0}">{{scope.row.activityStatus == 0?"未通过":scope.row.activityStatus == 1?"审核中":scope.row.activityStatus == 2?"进行中":"已完成"}}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="activityType" label="活动分类">
					<template slot-scope="scope">
						<span>{{scope.row.activityType == 3?"B类":"A类"}}</span>
					</template>
				</el-table-column>
				<el-table-column width="220px" prop="organizer" label="组织名称">
				</el-table-column>
				<el-table-column width="120px" prop="leaderName" label="活动领队">
					<template slot-scope="scope" >
						<span>{{scope.row.leaderName?scope.row.leaderName:'无'}}</span>
					</template>
				</el-table-column>
				<el-table-column width="100px" prop="action" label="操作">
					<template slot-scope="scope">
						<div class="table-action">
							<span @click="actionFn('detail', scope)">详情</span>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<div class="common-pageNation">
				<el-pagination background @current-change="currentChange" :current-page.sync="pageIndex"
					:page-size="pageSize" layout="total, prev, pager, next" :total="totalCount">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		listActivitySign
	} from "@/api/activity";
	export default {
		data() {
			return {
				currentTab: "0",
				totalCount: 0,
				pageIndex: 1,
				pageSize: 10,
				tableData: [],
				searchForm: {}
			};
		},
		mounted() {
			this.getListActivitySign();
		},
		methods: {
			dateFormat(intDate) {
				if (intDate) {
					return intDate.replace(" 00:00:00", "")
				}
			},
			currentChange(val) {
				this.pageIndex = val;
				this.tableData = [];
				this.getListActivitySign();
			},
			search() {
				this.pageIndex = 1;
				this.tableData = [];
				this.getListActivitySign();
			},
			reset() {
				this.searchForm = {}
				this.pageIndex = 1;
				this.tableData = [];
				this.getListActivitySign();
			},
			// 活动签到列表
			getListActivitySign() {
				let params = {
					pageIndex: this.pageIndex,
					pageSize: this.pageSize,
					activityStatus: 2
				}
				if (this.searchForm.activityName) {
					this.$set(params, "activityName", this.searchForm.activityName)
				}
				if (this.searchForm.activityId) {
					this.$set(params, "activityId", this.searchForm.activityId)
				}
				listActivitySign(params).then((res) => {
					if (res.code == 0) {
						this.tableData = res.data
						this.totalCount = res.page.totalCount * 1
					}
				})
			},
			actionFn(type, scope) {
				switch (type) {
					case "detail":
						this.$router.push({
							path: `/activitySign/detail?activitySignId=${scope.row.id}`
						})
						break;
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	/deep/.el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
	}
	
	/deep/.el-button {
		height: 36px;
	}
	.activityList {
		.activityList-data {
			display: flex;
			flex-direction: column;
			padding: 0 20px;
			margin-top: 20px;

			.common-row {
				margin: 16px 0;

				.common-row-item {
					margin-right: 20px;

					.common-row-ipt {
						flex: 1;
						height: 36px;
						line-height: 36px;
					}
				}
			}

			.table-action-date {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				span {
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 20px;
					color: #303133;
				}
			}

			.table-action {
				.table-action-status_black {
					color: #303133;
				}

				.table-action-status_orange {
					color: #f5803e;
				}

				.table-action-status_red {
					color: #e63757;
				}
			}
		}
	}
</style>
